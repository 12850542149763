import React from 'react'


export function BannerWhatsapp() {
  return (
    <div>
        <div className="rbt-callto-action-area d-none">
            <div className="rbt-callto-action rbt-cta-default style-4 variation-2 bg-gradient-6 mt--75">
                <div className="container">
                    <div className="row align-items-center content-wrapper row--30 mt_dec--30 position-relative">
                        <div className="col-lg-8 mt--30 offset-lg-3">
                            <div className="inner">
                                <div className="content text-left">
                                    <h2 className="title text-white">¿Quieres enterarte del día a día de
                                    <br /> PLearning?... Ùnete a nuestro canal WhatsApp </h2>
                                    <div className="call-to-btn text-start mt--30">
                                        <a className="rbt-btn btn-gradient hover-icon-reverse" href="https://whatsapp.com/channel/0029VaVQqVJ8fewhTDP7F71e">
                                            <span className="icon-reverse-wrapper">
                                                <span className="btn-text">Unirme</span>
                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                            <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                            </span>
                                        </a> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <img className="w-100" src="assets/images/micrositio/PLe_HomeWA_2024.png" alt="Shape Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export function SeccionUno() {
    return (
        <div className="service-wrapper rbt-section-gap bg-color-white pb--40" style={{paddingTop: '40px'}}>
        <div className="container">
            
            <div className="row">
                    <div className="swiper service-item-3-activation rbt-arrow-between gutter-swiper-30 swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">

                    <div className="swiper-wrapper" id="swiper-wrapper-c15f73fcaaa32863" aria-live="polite" style={{transform: 'translate3d(-445px, 0px, 0px)', transitionDuration: '0ms'}}>
                        {/* Start Single Card  */}
                        <div className="swiper-slide swiper-slide-prev" role="group" aria-label="1 / 4" style={{width: '445px'}}>
                            <div className="single-slide">
                                <div className="rbt-service rbt-service-2 rbt-hover-02 bg-no-shadow card-bg-1">
                                    <div className="inner">
                                        <div className="content">
                                            <h4 className="title"><a href="#">React</a></h4>
                                            <p>React Js dolor sit, amet consectetur.</p>
                                            <a className="transparent-button" href="#">Learn More<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a>
                                        </div>
                                        <div className="thumbnail">
                                            <img src="/assets/images/micrositio/PLe_HomeNovedadA_2024.png" alt="Education Images" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Card  */}
                        {/* Start Single Card  */}
                        <div className="swiper-slide" role="group" aria-label="4 / 4" style={{width: '445px'}}>
                            <div className="single-slide">
                                <div className="rbt-service rbt-service-2 rbt-hover-02 bg-no-shadow" style={{background:'#0A78C2'}}>
                                    <div className="inner">
                                        <div className="content">
                                            <h4 className="title"><a href="#">Panel de Líderes</a></h4>
                                            <p style={{color:'#D4EDDD'}}>Lleva a tu compañía a la victoria </p>
                                            {/* <a className="transparent-button" href="#">Learn More<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a> */}
                                        </div>
                                        <div className="thumbnail">
                                            <img src="/assets/images/micrositio/PLe_HomeNovedadA_2024.png" alt="Education Images" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Card  */}
                        {/* Start Single Card  */}
                        <div className="swiper-slide swiper-slide-active" role="group" aria-label="2 / 4" style={{width: '445px'}}>
                            <div className="single-slide">
                                <div className="rbt-service rbt-service-2 rbt-hover-02 bg-no-shadow" style={{background:'#E5E6E8'}}>
                                    <div className="inner">
                                        <div className="content">
                                            <h4 className="title"><a href="#">Jackpot</a></h4>
                                            <p>Cada mes, gana y diviértete con nuestro jackpot</p>
                                            <a className="transparent-button" href="/jackpot">Saber más<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a>
                                        </div>
                                        <div className="thumbnail">
                                            <img src="/assets/images/micrositio/PLe_HomeNovedadB_2024.png" alt="Education Images" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Card  */}
                        {/* Start Single Card  */}
                        <div className="swiper-slide swiper-slide-next" role="group" aria-label="3 / 4" style={{width: '445px'}}>
                            <div className="single-slide">
                                <div className="rbt-service rbt-service-2 rbt-hover-02 bg-no-shadow" style={{background:'#F6D079'}}>
                                    <div className="inner">
                                        <div className="content">
                                            <h4 className="title"><a href="/recompensas">Premios Especiales</a></h4>
                                            <p>Convierte tus créditos en regalos </p>
                                            <a className="transparent-button" href="/recompensas">Saber más<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a>
                                        </div>
                                        <div className="thumbnail">
                                            <img src="/assets/images/micrositio/PLe_HomeNovedadC_2024.png" alt="Education Images" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Card  */}

                        
                    </div>

                    {/* <div className="rbt-swiper-arrow rbt-arrow-left swiper-button-disabled" tabIndex="-1" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-c15f73fcaaa32863" aria-disabled="true">
                        <div className="custom-overfolow">
                            <i className="rbt-icon feather-arrow-left"></i>
                            <i className="rbt-icon-top feather-arrow-left"></i>
                        </div>
                    </div>

                    <div className="rbt-swiper-arrow rbt-arrow-right" tabIndex="0" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-c15f73fcaaa32863" aria-disabled="false">
                        <div className="custom-overfolow">
                            <i className="rbt-icon feather-arrow-right"></i>
                            <i className="rbt-icon-top feather-arrow-right"></i>
                        </div>
                    </div> */}
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                </div>


            </div>
        </div>
    </div>
    )
}

export function SeccionDos() {
    return (
        <div className="rbt-rbt-blog-area rbt-section-gapTop bg-color-white pt--0 d-none">
            <div className="container">

                <div className="row mb--15 g-5 align-items-end">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="section-title text-start">
                            <h4 className="title">¡Tenemos más para ti!</h4>
                        </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-12">
                        <div className="read-more-btn text-start text-md-end">
                            <a className="rbt-moderbt-btn" href="#">
                                <span className="moderbt-btn-text">View All News</span>
                                <i className="feather-arrow-right"></i>
                            </a>
                        </div>
                    </div> */}
                </div>

                {/* Start Card Area */}
                <div className="row g-5">
                    {/* Start Single Card  */}
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="rbt-card variation-02 rbt-hover">
                            <div className="rbt-card-img">
                                <a href="/champions">
                                    <img src="/assets/images/micrositio/PLe_Otras-sesionesA_bann_2024.png" alt="Card image" /> </a>
                            </div>
                            <div className="rbt-card-body">
                                <h5 className="rbt-card-title"><a href="/champions">Desafío Champions</a></h5>
                                <p className="rbt-card-text">Elige tu desafío. Vende productos participantes. Gana premios en efectivo..</p>
                                <div className="rbt-card-bottom">
                                    <a className="transparent-button" href="/champions">Conoce más <i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Card  */}

                    {/* Start Single Card  */}
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="rbt-card variation-02 rbt-hover">
                            <div className="rbt-card-img">
                                <a href="/otras-sesiones">
                                    <img src="/assets/images/micrositio/PLe_Otras-sesionesB_bann_2024.png" alt="Card image" /> </a>
                            </div>
                            <div className="rbt-card-body">
                                <h5 className="rbt-card-title"><a href="/otras-sesiones">Sesiones Extras</a></h5>
                                <p className="rbt-card-text">Especialízate en Panduit y gana puntos extra</p>
                                <div className="rbt-card-bottom">
                                    <a className="transparent-button" href="/otras-sesiones">Conoce más<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Card  */}

                    {/* Start Single Card  */}
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="rbt-card variation-02 rbt-hover">
                            <div className="rbt-card-img">
                                <a href="/otras-sesiones">
                                    <img src="/assets/images/micrositio/PLe_Otras-sesionesC_bann_2024.png" alt="Card image" /> </a>
                            </div>
                            <div className="rbt-card-body">
                                <h5 className="rbt-card-title"><a href="/otras-sesiones">Sesiones Especiales</a></h5>
                                <p className="rbt-card-text">Los webinars de Panduit también te dan puntos.</p>
                                <div className="rbt-card-bottom">
                                    <a className="transparent-button" href="/otras-sesiones">Conoce más<i><svg width="17" height="12" xmlns="http://www.w3.org/2000/svg"><g stroke="#27374D" fill="none" fillRule="evenodd"><path d="M10.614 0l5.629 5.629-5.63 5.629"></path><path strokeLinecap="square" d="M.663 5.572h14.594"></path></g></svg></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Card  */}
                </div>
                {/* End Card Area */}
            </div>
        </div>
    )
}

export function MultiSteps1() {
    <div>
        <div>
            <div className='progress-bar'>
                <div className='progress'></div>
            </div>

            <div className='container'>
                <div>
                <div className='form'>
                    <label>Pregunta 1</label>
                    <input type='text'></input>

                    <label>Pregunta 2</label>
                    <input type='text'></input>

                    <label>Pregunta 3</label>
                    <input type='text'></input>
                </div>
                </div>
            </div>

            <div className='btn-container'>
                <button className='btn'>Anterior</button>
                <button className='btn'>Siguiente</button>
            </div>
        </div>
    </div>
}

export function MultiSteps2() {
    <div>
        
    </div>
}
export default BannerWhatsapp