import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa1, fa2, fa3, faCrown } from "@fortawesome/free-solid-svg-icons";


import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { SeccionUno, SeccionDos, MultiSteps1 } from "../components/sections";
import ToastNotification from "../components/ToastNotification";
import { Container, Row, Col, Carousel, Card, Button, ProgressBar, Modal, Form,  FormCheck  } from 'react-bootstrap';
import {   BrowserRouter as Router,
    useHistory, useParams } from 'react-router-dom';
import { useVariablesGlobales } from '../globales/VariablesGlobales';
import axios from 'axios';

import {Activos, Participantes} from '../components/DoughnutCharts';
import PolarAreaChart from '../components/PolarAreaChart';
import LinesChart from '../components/LineCharts';
import { UsuariosParticipantes, UsuariosActivos, PanelLider } from '../components/EChartsBarChart';

axios.defaults.withCredentials = false;
axios.defaults.withXSRFToken = true;

export function ListaDeLogros() {
  return (
    <div classNameName='contenedor-principal'>
        <Container>
          <Row>
            <Col>      
              <h2>Logros</h2>
              <p>Listado de los logros disponibles</p>
            </Col>
          </Row>
        </Container>
      </div>
  );
}
export function Logro() {
    return (
      <div classNameName='contenedor-principal'>
        <Container>
          <Row>
            <Col>      
              <h2>Logro</h2>
              <p>Detalles del logro y confirmación de participación</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

export function Champions() {
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    const [listadoLogros, setListadoLogros] = useState(null);
    const [listadoParticipaciones, setListadoParticipaciones] = useState(null);
    const [cantidadParticipaciones, setCantidadParticipaciones] = useState(0);
    const [cantidadCompletados, setCantidadCompletados] = useState(0);
    const [premiosAcumulados, setPremiosAcumulados] = useState(0);
    const [nivelUsuario, setNivelUsuario] = useState('usuario');
    const [championsA, setChampionsA] = useState('no');
    const [championsB, setChampionsB] = useState('no');
    const [mostrarChampions, setMostrarChampions] = useState(false);
    const [activeItem, setActiveItem] = useState("overview");
    const { nombreRegion, setNombreRegion } = useVariablesGlobales();

    console.log("Viene de parte de logros: " + nombreRegion);


    useEffect(() => {
        // llamada para los datos de la temporada
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        axios.get('https://plsystem.quarkservers2.com/api/lista_logros', {
            params: {
                id_cuenta: cuentaPL,
                id_usuario: id_local
            }
            })
            .then(function (response) {
            //console.log(response);
            //console.log('logros filtrados');
            setListadoLogros(response.data.logros);
            setListadoParticipaciones(response.data.participaciones);
            setCantidadParticipaciones(response.data.participaciones.length);
            let contador = 0
            for (let i = 0; i < response.data.participaciones.length; i++) {
                if (response.data.participaciones[i].estado === "finalizado") {
                    contador++;
                }
            }
            setCantidadCompletados(contador);
            setPremiosAcumulados(response.data.premios_acumulados);
            setChampionsA(response.data.nivel_usuario);
            console.log(response.data.nivel_usuario);
            setChampionsA(response.data.champions_a);
            console.log(response.data.champions_a);
            setChampionsB(response.data.champions_b);
            console.log(response.data.champions_b);

            // Validación de champions
            if(response.data.nivel_usuario=='ventas')
            {
                if(response.data.champions_a=='si'){
                    setMostrarChampions(true);
                }
            }

            if(response.data.nivel_usuario=='especialista')
                {
                    if(response.data.champions_a=='si'&&response.data.champions_b=='si'){
                        setMostrarChampions(true);
                    }
                }

            
            
            })
            .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
            });
    
        }, []);

    const handleItemClick = (item) => {
        setActiveItem(item);
    };

    const [showToast, setShowToast] = useState(true);

    const handleShowToast = () => {
        setShowToast(true);
        setTimeout(() => setShowToast(true), 3000); // Ocultar después de 3 segundos
    };
    
    if(!mostrarChampions){
        return(
            <div>
              <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner text-center">
                                <h2 className="title">
                                    {nombreRegion === "RoLA" ? "Desafío Champions - " : "Desafío Champions - México"}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

              <ToastNotification show={showToast} onClose={() => setShowToast(false)} />
        
              <div className="rbt-course-details-area ptb--0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--0">
                                <p className="description has-medium-font-size mt--20">
                                <strong> ¡Bienvenido a Desafío Champions!</strong>, una exclusiva sección en la que pondrás a prueba todo lo que has aprendido en nuestros entrenamientos sobre soluciones eléctricas Panduit.
                                <br></br>
                                <strong> Elige tu desafío. Vende productos participantes. Gana premios en efectivo.</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-12">
                            <div className="course-details-content">
                                <div className="rbt-inner-onepage-navigation sticky-top mt--30">
                                    <nav className="mainmenu-nav onepagenav">
                                        <ul className="mainmenu" style={{fontWeight:'700'}}>
                                            <li className={activeItem === "overview" ? "current" : ""}>
                                                <a href="#overview" onClick={() => handleItemClick("overview")}>
                                                ¿Cómo Competir?
                                                </a>
                                            </li>
                                            <li className={activeItem === "coursecontent" ? "current" : ""}>
                                                <a href="#coursecontent" onClick={() => handleItemClick("coursecontent")}>
                                                Elige tu desafío
                                                </a>
                                            </li>
                                            <li className={activeItem === "details" ? "current" : ""}>
                                                <a href="#details" onClick={() => handleItemClick("details")}>
                                                Mis desafíos
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
        
                                {/*Start Course Feature Box  */}
                                <div className="rbt-course-feature-box overview-wrapper rbt-shadow-box mt--30 has-show-more" id="overview">
                                      <div className="container">
                                          <div className="row mb--60">
                                              <div className="col-lg-12">
                                                  <div className="section-title text-left">
                                                      <h2 className="title">¿Como Competir?</h2>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row row--15 mt_dec--30">
        
                                              {/*  Start Service Grid */}
                                              <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                                  <div className="service-card service-card-6">
                                                      <div className="inner">
                                                          <div className="icon">
                                                              <img src="assets/images/micrositio/PLe_DChico01_2024.png" alt="icons Images" />
                                                          </div>
                                                          <div className="content">
                                                              <h6 className="title"><a href="#">Elige</a></h6>
                                                              <p className="description">una categoría entre las disponibles.</p>
                                                          </div>
                                                          <span className="number-text">1</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/*  End Service Grid */}
        
                                              {/*  Start Service Grid */}
                                              <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                                  <div className="service-card service-card-6">
                                                      <div className="inner">
                                                          <div className="icon">
                                                              <img src="assets/images/micrositio/PLe_DChico0_2024.png" alt="Shape Images" />
                                                          </div>
                                                          <div className="content">
                                                              <h6 className="title"><a href="#">Vende </a></h6>
                                                              <p className="description">los productos participantes durante la vigencia del programa.
        </p>
                                                          </div>
                                                          <span className="number-text">2</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/*  End Service Grid */}
        
                                              {/*  Start Service Grid */}
                                              <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                                  <div className="service-card service-card-6">
                                                      <div className="inner">
                                                          <div className="icon">
                                                              <img src="assets/images//micrositio/PLe_DChico03_2024.png" alt="Shape Images" />
                                                          </div>
                                                          <div className="content">
                                                              <h6 className="title"><a href="#">Alcanza </a></h6>
                                                              <p className="description">las metas objetivo de cada nivel para obtener un bono.</p>
                                                          </div>
                                                          <span className="number-text">3</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/*  End Service Grid */}
        
                                              {/*  Start Service Grid */}
                                              <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                                  <div className="service-card service-card-6">
                                                      <div className="inner">
                                                          <div className="icon">
                                                              <img src="assets/images/micrositio/PLe_DChico04_2024.png" alt="Shape Images" />
                                                          </div>
                                                          <div className="content">
                                                              <h6 className="title"><a href="#">Envíanos </a></h6>
                                                              <p className="description">tus pruebas de compra (factura u orden de compra).</p>
                                                          </div>
                                                          <span className="number-text">4</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/*  End Service Grid */}
                                              {/*  Start Service Grid */}
                                              <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                                  <div className="service-card service-card-6">
                                                      <div className="inner">
                                                          <div className="icon">
                                                              <img src="assets/images/micrositio/PLe_DChico05_2024.png" alt="Shape Images" />
                                                          </div>
                                                          <div className="content">
                                                              <h6 className="title"><a href="#">Recibe  </a></h6>
                                                              <p className="description">Un bono acumulable por cada desafío completado</p>
                                                          </div>
                                                          <span className="number-text">5</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/*  End Service Grid */}
        
        
                                          </div>
                                      </div>
                                  
                                </div>
                                {/*End Course Feature Box  */}
        
                                {/*Start Course Content  */}
                                <div className="course-content rbt-shadow-box coursecontent-wrapper mt--30" id="coursecontent">
                                      <div className="container">
                                          <div className="row g-5 mb--60">
                                              <div className="col-lg-6 col-md-6 col-12">
                                                  <div className="section-title text-start">
                                                      <h2 className="title">No puedes participar en Champions</h2>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        );
    }
    if(listadoLogros){
        return (
            <div>
              <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner text-center">
                                <h2 className="title">
                                    {nombreRegion === "RoLA" ? "Desafío Champions - Latinoamérica" : "Desafío Champions - México"}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
        
              <ToastNotification show={showToast} onClose={() => setShowToast(false)} />

              <div className="rbt-course-details-area ptb--0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="section-title text-center mb--0 ">
                                <p className="description has-medium-font-size mt--20 pl--240 pr--240">
                                <strong> ¡Bienvenido a Desafío Champions!</strong>, una exclusiva sección en la que pondrás a prueba todo lo que has aprendido en nuestros entrenamientos sobre soluciones eléctricas Panduit.
                                <br></br>
                                <br></br>

<strong> Elige tu desafío. Vende productos participantes. Gana premios en efectivo.</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-12">
                            <div className="course-details-content">
                                <div className="rbt-inner-onepage-navigation sticky-top mt--30">
                                    <nav className="mainmenu-nav onepagenav">
                                        <ul className="mainmenu" style={{fontWeight:'700'}}>
                                            <li className={activeItem === "overview" ? "current" : ""}>
                                                <a href="#overview" onClick={() => handleItemClick("overview")}>
                                                ¿Cómo Competir?
                                                </a>
                                            </li>
                                            <li className={activeItem === "coursecontent" ? "current" : ""}>
                                                <a href="#coursecontent" onClick={() => handleItemClick("coursecontent")}>
                                                Elige tu desafío
                                                </a>
                                            </li>
                                            <li className={activeItem === "details" ? "current" : ""}>
                                                <a href="#details" onClick={() => handleItemClick("details")}>
                                                Mis desafíos
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
        
                                {/*Start Course Feature Box  */}
                                <div className="rbt-course-feature-box overview-wrapper rbt-shadow-box mt--30 has-show-more" id="overview">
                                      <div className="container">
                                          <div className="row mb--60">
                                              <div className="col-lg-12">
                                                  <div className="section-title text-left">
                                                      <h2 className="title">¿Como Competir?</h2>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row row--15 mt_dec--30">
        
                                              {/*  Start Service Grid */}
                                              <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                                  <div className="service-card service-card-6">
                                                      <div className="inner">
                                                          <div className="icon">
                                                              <img src="assets/images/micrositio/PLe_DChico01_2024.png" alt="icons Images" />
                                                          </div>
                                                          <div className="content">
                                                              <h6 className="title"><a href="#">Elige</a></h6>
                                                              <p className="description">una categoría entre las disponibles.</p>
                                                          </div>
                                                          <span className="number-text">1</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/*  End Service Grid */}
        
                                              {/*  Start Service Grid */}
                                              <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                                  <div className="service-card service-card-6">
                                                      <div className="inner">
                                                          <div className="icon">
                                                              <img src="assets/images/micrositio/PLe_DChico0_2024.png" alt="Shape Images" />
                                                          </div>
                                                          <div className="content">
                                                              <h6 className="title"><a href="#">Vende </a></h6>
                                                              <p className="description">los productos participantes durante la vigencia del programa.
        </p>
                                                          </div>
                                                          <span className="number-text">2</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/*  End Service Grid */}
        
                                              {/*  Start Service Grid */}
                                              <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                                  <div className="service-card service-card-6">
                                                      <div className="inner">
                                                          <div className="icon">
                                                              <img src="assets/images//micrositio/PLe_DChico03_2024.png" alt="Shape Images" />
                                                          </div>
                                                          <div className="content">
                                                              <h6 className="title"><a href="#">Alcanza </a></h6>
                                                              <p className="description">las metas objetivo de cada nivel para obtener un bono.</p>
                                                          </div>
                                                          <span className="number-text">3</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/*  End Service Grid */}
        
                                              {/*  Start Service Grid */}
                                              <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                                  <div className="service-card service-card-6">
                                                      <div className="inner">
                                                          <div className="icon">
                                                              <img src="assets/images/micrositio/PLe_DChico04_2024.png" alt="Shape Images" />
                                                          </div>
                                                          <div className="content">
                                                              <h6 className="title"><a href="#">Envíanos </a></h6>
                                                              <p className="description">tus pruebas de compra (factura u orden de compra).</p>
                                                          </div>
                                                          <span className="number-text">4</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/*  End Service Grid */}
                                              {/*  Start Service Grid */}
                                              <div className="col-lg-3 col-xl-2 col-xxl-2 col-md-6 col-sm-6 col-12 mt--30">
                                                  <div className="service-card service-card-6">
                                                      <div className="inner">
                                                          <div className="icon">
                                                              <img src="assets/images/micrositio/PLe_DChico05_2024.png" alt="Shape Images" />
                                                          </div>
                                                          <div className="content">
                                                              <h6 className="title"><a href="#">Recibe  </a></h6>
                                                              <p className="description">Un bono acumulable por cada desafío completado</p>
                                                          </div>
                                                          <span className="number-text">5</span>
                                                      </div>
                                                  </div>
                                              </div>
                                              {/*  End Service Grid */}
        
        
                                          </div>
                                      </div>
                                  
                                </div>
                                {/*End Course Feature Box  */}
        
                                {/*Start Course Content  */}
                                <div className="course-content rbt-shadow-box coursecontent-wrapper mt--30" id="coursecontent">
                                      <div className="container">
                                          <div className="row g-5 mb--60">
                                              <div className="col-lg-6 col-md-6 col-12">
                                                  <div className="section-title text-start">
                                                      <h2 className="title">Elige tu desafío</h2>
                                                  </div>
                                              </div>
                                              <div className="col-lg-6 col-md-6 col-12">
                                                  <div className="pricing-billing-duration text-start text-md-end">
                                                    <div className="section-title text-start" style={{float:'right'}}>
                                                        <span className="subtitle text-white">VENDEDOR</span>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="row gx-0 ">
                                          {listadoLogros && listadoLogros.map((logro, index) => (
                                              <div className="col-xl-4 col-lg-6 col-md-6 col-12">
                                                  <div className="pricing-table style-3">
                                                      <div className="icon-image text-center">
                                                      <img src={`https://plsystem.quarkservers2.com/img/publicaciones/${logro.imagen}`} alt="Card image" />
                                                      </div>
                                                      <div className="pricing-header">
                                                          <h3 className="title">{logro.nombre}</h3>
                                                          <span className="rbt-badge mb--15">Gana hasta</span>
                                                          <div className="price-wrap">
                                                              <div className="monthly-pricing" style={{display: 'block'}}>
                                                                  <span className="amount">{logro.premio}</span>
                                                                    <span className="duration pl--10"> 
                                                                    {nombreRegion === "RoLA" ? "USD" : "MXN"}
                                                                    </span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="pricing-body pl--40 pr--40">
                                                          <ul className="list-item">
                                                              <li>{logro.instrucciones}</li>
                                                          </ul>
                                                      </div>
                                                      <div className="pricing- d-none" >
                                                          <a className="rbt-btn btn-border rbt-switch-btn rbt-switch-y radius-round hover-icon-reverse w-100" href={`/detalle-champions/${logro.id}`} style={{background:'#213746', color:'#fff'}}>
                                                              
                                                              <span data-text="SUSCRIBIRSE">VER DETALLE   </span>
                                                              
                                                          </a>
                                                      </div>
                                                  </div>
                                              </div>
                                            ))}
                                          </div>
                                      </div>
                                </div>
                                    <div className="course-content rbt-shadow-box coursecontent-wrapper mt--30" id="coursecontent">
                                        <div className="container">
                                                <div className="content" id="details">
                                                    <div className="section-title">
                                                        <h2 className="tittle">Mis desafíos</h2>
                                                    </div>
                                                    <div className="row g-5">
                                                        {/* Start Single Card  */}
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                            <div className="rbt-counterup variation-01 rbt-hover-03 rbt-border-dashed">
                                                                <div className="inner">
                                                                    <div className="rbt-round-icon bg-primary-opacity">
                                                                        <i className="feather-book" style={{color:'#E5E6E8'}}></i>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h3 className="counter without-icon color-primary">{cantidadParticipaciones}</h3>
                                                                        <span className="rbt-title-style-2 d-block">Desafíos inscritos</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* End Single Card  */}
                                                        {/* Start Single Card  */}
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                            <div className="rbt-counterup variation-01 rbt-hover-03 rbt-border-dashed" style={{color:'#E5E6E8'}}>
                                                                <div className="inner">
                                                                    <div className="rbt-round-icon bg-primary-opacity">
                                                                        <i className="feather-award" style={{color:'#E5E6E8'}}></i>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h3 className="counter without-icon color-primary">{cantidadCompletados}</h3>
                                                                        <span className="rbt-title-style-2 d-block" style={{color:'#6c7588'}}>Desafíos completados</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                                                        {/* End Single Card  */}
                                                        {/* Start Single Card  */}
                                                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                                                            <div className="rbt-counterup variation-01 rbt-hover-03 rbt-border-dashed" style={{color:'#E5E6E8'}}>
                                                                <div className="inner">
                                                                    <div className="rbt-round-icon bg-primary-opacity">
                                                                        <i className="feather-dollar-sign" style={{color:'#E5E6E8'}}></i>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h3 className="counter without-icon color-primary">$ {premiosAcumulados.toFixed(2)}</h3>
                                                                        <span className="rbt-title-style-2 d-block" style={{color:'#6c7588'}}>Premios acumulados  </span>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                        </div>
                                                        
                                                        {/* End Single Card  */}
        
                                                        {/*Start Feture Box  */}
                                                        <div className="col-lg-12">
                                                            <table className="rbt-table table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Nombre del desafío</th>
                                                                        <th>Fecha de inscripción</th>
                                                                        <th>Fecha de finalización</th>
                                                                        <th>Ver detalle</th>
                                                                        <th>Progreso </th>
                                                                        <th className="d-none">Status </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {listadoParticipaciones && listadoParticipaciones.map((participacion, index) => (
                                                                    <tr>
                                                                        <th>
                                                                            <span className="h6 mb--5">{participacion.nombre}</span>
                                                                        </th>
                                                                        <td>
                                                                            <p className="b3 mb--5">{participacion.fecha_registro}</p>
                                                                        </td>
                                                                        <td>
                                                                            {participacion.fecha_finalizado ? (
                                                                                <p className="b3 mb--5">{participacion.fecha_finalizado}</p>
                                                                            ) : (
                                                                                <p className="b3 mb--5">-</p>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <a className="rbt-btn btn-xs bg-color-warning-opacity radius-round color-white" href={`/detalle-champions/${participacion.id_logro}`}>
                                                                                <i className="feather-eye pl--0"></i> Ver
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <p className="b3 mb--5">
                                                                            {participacion.confirmacion_nivel_especial === "si" ? "Nivel Especial" :
                                                                            participacion.confirmacion_nivel_c === "si" ? "Nivel C" :
                                                                            participacion.confirmacion_nivel_b === "si" ? "Nivel B" :
                                                                            participacion.confirmacion_nivel_a === "si" ? "Nivel A" : "-"}
                                                                            </p>
                                                                        </td>
                                                                        <td className="d-none">
                                                                            <div className="rbt-button-group justify-content-start">
                                                                            {participacion.estado === "participante" && (
                                                                                <a className="rbt-btn btn-xs bg-color-info-opacity radius-round color-info" href={`/detalle-champions/${participacion.id_logro}`} title="Sube tus evidencias" style={{background:'#213746', color:'#fff'}}>
                                                                                    <i className="feather-check-square pl--0"></i> Finalizar Nivel
                                                                                </a>
                                                                            )}
                                                                            {participacion.estado === "validando" && (
                                                                                <a className="rbt-btn btn-xs bg-color-warning-opacity radius-round color-white" href={`/detalle-champions/${participacion.id_logro}`} title="Esperando decisión del árbitro">
                                                                                    <i className="feather-check-square pl--0"></i> Validando
                                                                                </a>
                                                                            )}
                                                                            {participacion.estado === "finalizado" && (
                                                                                <a className="rbt-btn btn-xs bg-color-success-opacity radius-round color-success" href={`/detalle-champions/${participacion.id_logro}`} title="Haz completado el desafío">
                                                                                    <i className="feather-check-circle pl--0"></i> Finalizado
                                                                                </a>
                                                                            )}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                     ))}
                                                                    {/* 
                                                                    <tr>
                                                                        <th>
                                                                            <span className="h6 mb--5">Seguridad Industrial</span>
                                                                        </th>
                                                                        <td>
                                                                            <p className="b3 mb--5">December 26, 2023</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="b3 mb--5">-</p>
                                                                        </td>
                                                                        <td>
                                                                            <a className="rbt-btn btn-xs bg-color-warning-opacity radius-round color-white" href='/detalle-champions'>
                                                                                <i className="feather-eye pl--0"></i> Ver
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <p className="b3 mb--5">Nivel 3</p>
                                                                        </td>
                                                                        <td>
                                                                            <div className="rbt-button-group justify-content-start">
                                                                                <a className="rbt-btn btn-xs bg-color-info-opacity radius-round color-info" href="#" title="Edit" style={{background:'#213746', color:'#fff'}}><i className="feather-check-square pl--0"></i> Finalizar Nivel </a>
                                                                                <a className="rbt-btn btn-xs bg-color-success-opacity radius-round color-success" href="#" title="Delete"><i className="feather-check-circle pl--0"></i> Finalizar</a>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
        
                                                                    <tr>
                                                                        <th>
                                                                            <span className="h6 mb--5">Sostentabilidad</span>
                                                                        </th>
                                                                        <td>
                                                                            <p className="b3 mb--5">December 26, 2023</p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="b3 mb--5">-</p>
                                                                        </td>
                                                                        <td>
                                                                            <a className="rbt-btn btn-xs bg-color-warning-opacity radius-round color-white" href='/detalle-champions'>
                                                                                <i className="feather-eye pl--0"></i> Ver
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <p className="b3 mb--5">Nivel 2</p>
                                                                        </td>
                                                                        <td>
                                                                            <div className="rbt-button-group justify-content-start">
                                                                                <a className="rbt-btn btn-xs bg-color-info-opacity radius-round color-info" href="#" title="Edit" style={{background:'#213746', color:'#fff'}}><i className="feather-check-square pl--0"></i> Finalizar Nivel </a>
                                                                                <a className="rbt-btn btn-xs bg-color-success-opacity radius-round color-success" href="#" title="Delete"><i className="feather-check-circle pl--0"></i> Finalizar</a>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {/*End Feture Box  */}
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                {/*End Course Content  */}
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          )
    }
  
}

export function DetalleChampios (){

    const [modalVisible, setModalVisible] = useState(false);
    const { id } = useParams();
    const {cuentaPL, setCuentaPL} = useVariablesGlobales();
    const [detallesLogro, setDetallesLogro] = useState(null);
    const [detallesParticipacion, setDetallesParticipacion] = useState(null);
    const [listadoParticipaciones, setListadoParticipaciones] = useState(null);
    const [cantidadParticipaciones, setCantidadParticipaciones] = useState(0);
    const [cantidadParticipacionesPendientes, setCantidadParticipacionesPendientes] = useState(0);
    const [recargarDatos, setRecargarDatos] = useState(false);
    const [file, setFile] = useState(null);
    const [enviando, setEnviando] = useState(false);
    const [comprobanteNivel, setComprobanteNivel] = useState('a');
    const [showModalTerminos, setShowModalTerminos] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [datosTerminos, setDatosTerminos] = useState(null);
    const { nombreRegion, setNombreRegion } = useVariablesGlobales();

    console.log("Viene de parte de detalle logros: " + nombreRegion);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const borrarEvidencia = async (id) => {
        console.log(id);
        try {
            const response = await axios.post('https://plsystem.quarkservers2.com/api/borrar_evidencia', {
                id: id
            });
            console.log(response);
            setRecargarDatos(!recargarDatos);
        } catch (error) {
            //console.error('Error al subir archivo', error);
        }
    };

  const handleOpen = () => setShowModalTerminos(true);
  

  // Función para cerrar el modal
  const handleClose = () => setShowModalTerminos(false);

  // Función para manejar el clic en el botón Aceptar
  const handleAccept = () => {
    localStorage.setItem('aceptados_terminos', 'true'); // Guarda la variable local
    setShowModal(false); // Cierra el modal
  };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.size > 2 * 1024 * 1024) { // 2MB
            Swal.fire({
                title: 'El archivo no debe ser mayor a 2Mb',
                icon: 'error',
                confirmButtonColor: '#ff0000',
                confirmButtonText: 'Aceptar'
                });
                setFile(null);
            } else {
                setFile(e.target.files[0]);
            }
            
      };

      

      const handleSubmitFile = async (e) => {
        e.preventDefault();
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id_cuenta', cuentaPL);
        
        formData.append('id_usuario', id_local);
        formData.append('id_logro', detallesLogro.id);
        formData.append('id_participacion', detallesParticipacion.id);
        formData.append('nivel', comprobanteNivel);
        setEnviando(true);
        
    
        try {
            
          const response = await axios.post('https://plsystem.quarkservers2.com/api/subir_evidencia', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          });
          setEnviando(false);
          setRecargarDatos(!recargarDatos);
        } catch (error) {
          console.error('Error al subir archivo', error);
        }
      };



    useEffect(() => {
        // llamada para los datos de la temporada
        const id_local = JSON.parse(localStorage.getItem('idUsuario'));
        axios.get('https://plsystem.quarkservers2.com/api/detalles_logro', {
            params: {
                id_cuenta: cuentaPL,
                id_usuario: id_local,
                id: id
            }
            })
            .then(function (response) {
            //console.log(response);
            setDetallesLogro(response.data.logro);
            console.log(response.data.participaciones);
            setListadoParticipaciones(response.data.participaciones);
            setDetallesParticipacion(response.data.participacion);
            setCantidadParticipaciones(response.data.participaciones.length);
            setCantidadParticipacionesPendientes(response.data.participaciones_pendientes.length);
            
            })
            .catch(function (error) {
            // handle error
            console.log('El error fue:'+error);
            });

            
          
    
        }, [recargarDatos]);

        useEffect(() => {
            // Realiza la solicitud al servidor solo si id es válido
            axios.get('https://plsystem.quarkservers2.com/api/datos_publicacion', {
                params: {
                id: 6
                }
            })
            .then(function (response) {
                //console.log(response.data);
                setDatosTerminos(response.data);
                
            })
            .catch(function (error) {
                console.log('El error fue:' + error);
            });
        }, [recargarDatos, listadoParticipaciones])

        const handleParticipacion = (event) => {
            event.preventDefault();
            // Llamar a la función de manejo con los datos
            manejarDatos(detallesLogro.id);
        };

        const handleFinalizacion = (event) => {
            event.preventDefault();
            // Llamar a la función de manejo con los datos
            finalizarDatos(detallesLogro.id);
        };
        
    
        // Función para manejar los datos enviados
        const manejarDatos = async () => {
            const id_local = JSON.parse(localStorage.getItem('idUsuario'));
            try {
                const response = await axios.post('https://plsystem.quarkservers2.com/api/participar_logro', {
                    id_cuenta: cuentaPL,
                    id_logro: detallesLogro.id,
                    id_usuario: id_local
                });
                console.log(response);
                setRecargarDatos(!recargarDatos);
                Swal.fire({
                    title: '¡Te has unido al desafío, Felicidades!',
                    icon: 'success',
                    confirmButtonColor: '#213746',
                    confirmButtonText: 'Aceptar'
                    });
                
                } catch (error) {
                console.error('Error al enviar la solicitud:', error);
                }
        };

        const finalizarDatos = async () => {
            const id_local = JSON.parse(localStorage.getItem('idUsuario'));
            try {
                const response = await axios.post('https://plsystem.quarkservers2.com/api/validar_logro', {
                    id_cuenta: cuentaPL,
                    id_logro: detallesLogro.id,
                    id_usuario: id_local
                });
                console.log(response);
                setRecargarDatos(!recargarDatos);
                Swal.fire({
                    title: '¡Tus comprobantes han sido cargados!',
                    html: '<p style="font-size:12px">Revisaremos tus archivos a la brevedad y en un lapso de dos semanas como máximo, podrás visualizar los bonos en tu panel Champions.</p>',
                    icon: 'success',
                    confirmButtonColor: '#213746',
                    confirmButtonText: 'Aceptar'
                    });
                
                } catch (error) {
                console.error('Error al enviar la solicitud:', error);
                }
        };

        const abrirModal = () => {
            setModalVisible(true);
        };
    
        const cerrarModal = () => {
            setModalVisible(false);
        };
    
        const [showModal, setShowModal] = useState(false);
    
        const handleModalClose = () => setShowModal(false);
        const handleModalShow = () => setShowModal(true);

        const [showToast, setShowToast] = useState(true);

        const handleShowToast = () => {
            setShowToast(true);
            setTimeout(() => setShowToast(true), 3000); // Ocultar después de 3 segundos
        };

    if(detallesLogro && datosTerminos){
        return(
            <div>
                <ToastNotification show={showToast} onClose={() => setShowToast(false)} />

                {/* Start Accordion Area  */}
                <div className="rbt-breadcrumb-default ptb--100 ptb_md--50 ptb_sm--30 bg-gradient-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner text-center">
                                    <h2 className="title">
                                        {nombreRegion === "RoLA" ? "Desafío Champions - Latinoamérica" : "Desafío Champions - México"}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               {/* End Accordion Area  */}
    
                <div className="rbt-pricing-area bg-color-white rbt-section-gap pt--0 pb--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                </div>

                                <div className="section-title text-center">
                                    <h2 className="title">Desafío: {detallesLogro.nombre}</h2>
                                    <p className="description has-medium-font-size mt--20">{detallesLogro.instrucciones}</p>
                                </div>

                                <div className="rbt-button-group mt--30">
                                    <a className="rbt-btn btn-gradient hover-icon-reverse" href="/champions">
                                        <span className="icon-reverse-wrapper">
                                            <span className="btn-text">Volver</span>
                                        <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                        <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="row g-5 rbt-shadow-box mt--30">
                                <div className="container mt--0">
                                    <div className="row align-items-top justify-content-center mb-4 pb--30">
                                        <div className="col-lg-6 mt_md--40 mt_sm--40">
                                            <div className="inner pl--50 pl_sm--0 pl_md--0">
                                                <div className="section-title text-start">
                                                    <p className="description has-medium-font-size mt--20 mb--0" dangerouslySetInnerHTML={{ __html: detallesLogro.contenido }}></p>
                                                </div>

                                                <div className="rbt-feature-wrapper mt--0">

                                                    <div className="rbt-feature feature-style-1 align-items-center">
                                                        <div className="icon bg-icon-opacity-6">
                                                            <FontAwesomeIcon icon={fa1} />
                                                        </div>
                                                        <div className="feature-content">
                                                            <h6 className="feature-title" dangerouslySetInnerHTML={{ __html: detallesLogro.nivel_a }}></h6>
                                                        </div>
                                                    </div>

                                                    <div className="rbt-feature feature-style-1 align-items-center" style={{marginTop:'10px'}}>
                                                        <div className="icon bg-icon-opacity-7">
                                                            <FontAwesomeIcon icon={fa2} style={{color:'#fff'}} />
                                                        </div>
                                                        <div className="feature-content">
                                                            <h6 className="feature-title" dangerouslySetInnerHTML={{ __html: detallesLogro.nivel_b }}></h6>
                                                        </div>
                                                    </div>

                                                    <div className="rbt-feature feature-style-1 align-items-center" style={{marginTop:'10px'}}>
                                                        <div className="icon bg-icon-opacity-8" >
                                                        <FontAwesomeIcon icon={fa3} />
                                                        </div>
                                                        <div className="feature-content">
                                                            <h6 className="feature-title" dangerouslySetInnerHTML={{ __html: detallesLogro.nivel_c }}></h6>
                                                        </div>
                                                    </div>

                                                    {detallesLogro.nivel_especial ? (
                                                    <div className="rbt-feature feature-style-1 align-items-center" style={{marginTop:'10px'}}>
                                                        <div className="icon bg-icon-opacity-9">
                                                            <FontAwesomeIcon icon={faCrown} />
                                                        </div>
                                                        <div className="feature-content">
                                                            <h6 className="feature-title" dangerouslySetInnerHTML={{ __html: detallesLogro.nivel_especial }}></h6>
                                                        </div>
                                                    </div>
                                                    ):null}

                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center">
                                            <img 
                                                className="radius-small" 
                                                src={`https://plsystem.quarkservers2.com/img/publicaciones/${detallesLogro.imagen_fondo}`}  
                                                alt="Corporate Template" 
                                                onClick={handleModalShow}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <div 
                                                style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer', fontSize:'26px' }}
                                                onClick={handleModalShow}
                                            >
                                                VER DETALLE
                                            </div>

                                            <Modal show={showModal} onHide={handleModalClose} centered size="xl">
                                                <Modal.Header style={{ fontSize:'12px' }} closeButton></Modal.Header>
                                                <Modal.Body>
                                                    <img 
                                                        src={`https://plsystem.quarkservers2.com/img/publicaciones/${detallesLogro.imagen_fondo}`}  
                                                        alt="Corporate Template" 
                                                        style={{ width: '100%' }} 
                                                    />
                                                </Modal.Body>
                                            </Modal>
                                        </div>


                                    </div>
                                    {!detallesParticipacion ? (
                                    <a className="rbt-btn btn-gradient hover-icon-reverse ml--50" href="#"  onClick={handleParticipacion}>
                                        <span className="icon-reverse-wrapper">
                                        <span className="btn-text">Unirse al desafío</span>
                                        <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                        <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                        </span>
                                    </a>
                                     ):null}
                                        {detallesParticipacion ? (
                                    <div className="row align-items-top justify-content-center" style={{background:'#f4f4f4'}}>
                                        <div className="col-lg-8" style={{ padding:'30px', paddingTop:'15px'}}>
                                     
                                        
                                        <div className="rbt-accordion-style accordion pt--20">
                                            <div className="section-title text-start">
                                                <h4 className="title">¿Estás listo?</h4>
                                                <p className="description has-medium-font-size mt--20 mb--40"  style={{color:'#000'}}> Si has cumplido la cuota de cualquier nivel de este desafío, envíanos tus comprobantes para poder validar tu premio. Recuerda que <strong> puedes ir terminando los niveles en orden progresivo o saltarlos</strong> si consideras que te has adelantado a la cuota.
                                                <br/>
                                                <br/>
                                                <span className="d-none" style={{fontSize:'16px', color:'#990000'}}> <strong> SUBE TUS COMPROBANTES EN FORMATO PDF NO MAYOR A 2 Mb </strong></span>
                                                
                                                </p>

                                            </div>

                                                <div className="rbt-accordion-style rbt-accordion-05 accordion d-none">
                                                <div className="accordion" id="accordionExampleb5">
                                                    <div className="accordion-item card">
                                                    {detallesParticipacion && detallesParticipacion.estado == 'participante' && cantidadParticipaciones < detallesLogro.cantidad_evidencias ? (
                                                               
                                                        <h2 className="accordion-header card-header" id="headingFour2">
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour2" aria-expanded="true" aria-controls="collapseFour1">
                                                            Carga tus comprobantes aquí:
                                                            </button>
                                                        </h2>
                                                        ):null}
                                                        <div id="collapseFour2" className="accordion-collapse collapse show" aria-labelledby="headingFour2" data-bs-parent="#accordionExampleb5">
                                                            <div className="accordion-body card-body">
                                                            {detallesParticipacion && detallesParticipacion.estado == 'participante' && cantidadParticipaciones < detallesLogro.cantidad_evidencias ? (
                                                                <form onSubmit={handleSubmitFile} className="rbt-profile-row rbt-default-form row">
                                                                    <div className="col-lg-5 col-md-4 col-sm-4 col-12">
                                                                        <input type="file" onChange={handleFileChange} accept="application/pdf" style={{height:'60px', paddingTop:'14px'}} />
                                                                    </div>

                                                                    <div className="col-lg-3 col-md-4 col-sm-4 col-12">
                                                                        <button className="rbt-btn btn-sm" type="submit" disabled={enviando}>{enviando ? 'Enviando...' : 'Subir Archivo'}</button>
                                                                    </div>

                                                                </form>
                                                            ):null}
                                                            {listadoParticipaciones ? (
                                                                <>
                                                                <nav>
                                                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                                        <button className="nav-link active" id="nav-pendientes-tab" data-bs-toggle="tab" data-bs-target="#nav-pendientes" type="button" role="tab" aria-controls="nav-pendientes" aria-selected="true">Pendientes</button>
                                                                        <button className="nav-link" id="nav-validados-tab" data-bs-toggle="tab" data-bs-target="#nav-validados" type="button" role="tab" aria-controls="nav-validados" aria-selected="false">Validados</button>
                                                                        <button className="nav-link" id="nav-rechazados-tab" data-bs-toggle="tab" data-bs-target="#nav-rechazados" type="button" role="tab" aria-controls="nav-rechazados" aria-selected="false">Rechazados</button>
                                                                    </div>
                                                                </nav>
                                                                <div className="tab-content" id="nav-tabContent">
                                                                    <div className="tab-pane fade show active" id="nav-pendientes" role="tabpanel" aria-labelledby="nav-pendientes-tab" tabindex="0">
                                                                    <table className="rbt-table table table-borderless">
                                                                        <tbody>
                                                                        {listadoParticipaciones.map((participacion, index) => (
                                                                            <tr key={index}>
                                                                                {participacion.validado==='no' && index < detallesLogro.cantidad_evidencias ? (
                                                                                    <>
                                                                                        {detallesParticipacion && detallesParticipacion.estado === 'participante' ? (
                                                                                        <td style={{width:'0px'}}>
                                                                                            <button className="btn btn-link pt--0" style={{fontSize:'18px'}} onClick={() => borrarEvidencia(participacion.documento)}><i className="fa fa-times-circle text-danger"></i></button>
                                                                                        </td>
                                                                                        ):null}
                                                                                        <td>
                                                                                            <a title={participacion.documento} href={`https://plsystem.quarkservers2.com/img/evidencias/${participacion.documento}`}>{participacion.documento}</a>
                                                                                            {detallesParticipacion && detallesParticipacion.estado === 'validando' ? (
                                                                                            <span className="rbt-btn btn-xs bg-color-warning-opacity radius-round ms-2"><i className="feather-check"></i></span>    
                                                                                            ):null}
                                                                                        </td>
                                                                                        
                                                                                        <td style={{color:'#3c3b3b'}}>{participacion.fecha_registro}</td>
                                                                                    </>
                                                                                ) : null}
                                                                            </tr>
                                                                        ))}
                                                                        </tbody>
                                                                    </table>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="nav-validados" role="tabpanel" aria-labelledby="nav-validados-tab" tabindex="0">
                                                                        <table className="rbt-table table table-borderless">
                                                                            <tbody>
                                                                            {listadoParticipaciones.map((participacion, index) => (
                                                                                <tr key={index}>
                                                                                    {participacion.validado==='si' ? (
                                                                                        <>
                                                                                            <td>
                                                                                                <a title={participacion.documento} href={`https://plsystem.quarkservers2.com/img/evidencias/${participacion.documento}`}>{participacion.documento}</a>
                                                                                                {detallesParticipacion && detallesParticipacion.estado === 'validando' ? (
                                                                                                <span className="rbt-btn btn-xs bg-color-warning-opacity radius-round ms-2"><i className="feather-check"></i></span>    
                                                                                                ):null}
                                                                                            </td>
                                                                                            <td>
                                                                                                Nivel {participacion.nivel}
                                                                                            </td>
                                                                                            
                                                                                            <td style={{color:'#3c3b3b'}}>{participacion.fecha_registro}</td>
                                                                                        </>
                                                                                    ) : null}
                                                                                </tr>
                                                                            ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="tab-pane fade" id="nav-rechazados" role="tabpanel" aria-labelledby="nav-rechazados-tab" tabindex="0">
                                                                        <table className="rbt-table table table-borderless">
                                                                            <tbody>
                                                                            {listadoParticipaciones.map((participacion, index) => (
                                                                                <tr key={index}>
                                                                                    {participacion.validado==='rechazar'? (
                                                                                        <>
                                                                                            <td>
                                                                                                <a title={participacion.documento} href={`https://plsystem.quarkservers2.com/img/evidencias/${participacion.documento}`}>{participacion.documento}</a>
                                                                                                {detallesParticipacion && detallesParticipacion.estado === 'validando' ? (
                                                                                                <span className="rbt-btn btn-xs bg-color-warning-opacity radius-round ms-2"><i className="feather-check"></i></span>    
                                                                                                ):null}
                                                                                                <br/>
                                                                                                <b>Comentario:</b> {participacion.comentario}
                                                                                            </td>
                                                                                            <td>
                                                                                                Nivel {participacion.nivel}
                                                                                            </td>
                                                                                            
                                                                                            <td style={{color:'#3c3b3b'}}>{participacion.fecha_registro}</td>
                                                                                        </>
                                                                                    ) : null}
                                                                                </tr>
                                                                            ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                
                                                                </>
                                                                ):null }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                {detallesParticipacion && detallesParticipacion.estado === 'participante' ? (

                                                <div className="d-flex align-items-center">
                                                

                                                <Form.Check
                                                className="ms-4 me-2 mt-4 d-none"
                                                type={'checkbox'}
                                                id={`default-checkbox`}
                                                label={`He leido y acepto los`}
                                                onChange={handleCheckboxChange}
                                                />
                                                <a href="#" className="mt-4 d-none" onClick={handleOpen}>Terminos y condiciones</a>
                                                </div>
                                                ):null}

                                                <Modal className="popup-notificaciones" show={showModalTerminos} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
                                                    <Modal.Body>
                                                    <div dangerouslySetInnerHTML={{ __html: datosTerminos.contenido }} />
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <div className="row w-100">
                                                            <div className="col-2">
                                                            <Button variant="secondary" className="rbt-btn rbt-switch-btn" onClick={handleClose}>
                                                                Cerrar
                                                            </Button>
                                                            </div>
                                                        </div>
                                                    </Modal.Footer>
                                                </Modal>
                                                {detallesParticipacion.estado === "participante" ? (
                                                <button className="rbt-btn btn-gradient hover-icon-reverse mt--10 d-none" href="#" onClick={handleFinalizacion} disabled={!isChecked} >
                                                    <span className="icon-reverse-wrapper">
                                                    <span className="btn-text">Finalizar Nivel(es)</span>
                                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                    <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                    </span>
                                                </button>
                                                ):null}
                                               
                                        </div>
                                        
                                        
                                        </div>
                                        <div className="col-lg-4 pl--60 pr--30 pt--35">
                                        <h4 className="title text-left">Mis Desafíos</h4>

                                            {detallesParticipacion ? (
                                                <table className="rbt-table table table-borderless">
                                                    
                                                        <tbody>
                                                        <tr style={{borderBottom:'1px solid #dee2e6'}}>
                                                            <th>Fecha de registro</th>
                                                            <td>{detallesParticipacion.fecha_registro}</td>
                                                        </tr>
                                                        <tr style={{borderBottom:'1px solid #dee2e6'}}>
                                                            <th>Archivos Subidos</th>
                                                            <td>{cantidadParticipaciones}</td>
                                                        </tr>
                                                        <tr style={{borderBottom:'1px solid #dee2e6'}}>
                                                            <th>Nivel Alcanzado</th>
                                                            <td>
                                                            {detallesParticipacion.confirmacion_nivel_especial === "si" ? (
                                                                    <p>Nivel Especial</p>
                                                                ) : (
                                                                    detallesParticipacion.confirmacion_nivel_c === "si" ? (
                                                                        <p>Nivel C</p>
                                                                    ) : (
                                                                        detallesParticipacion.confirmacion_nivel_b === "si" ? (
                                                                            <p>Nivel B</p>
                                                                        ) : (
                                                                            detallesParticipacion.confirmacion_nivel_a === "si" ? (
                                                                                <p>Nivel A</p>
                                                                            ) : (
                                                                                <p>-</p>
                                                                            )
                                                                        )
                                                                    )
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr style={{borderBottom:'1px solid #dee2e6'}}>
                                                            <th>Estado</th>
                                                            <td>
                                                            {detallesParticipacion.estado === "participante" ? (
                                                                <span className="rbt-btn btn-xs bg-color-info-opacity radius-round color-info" style={{background: 'rgb(33, 55, 70)',  color: 'rgb(255, 255, 255)', fontSize:'16px'}}>
                                                                <i className="feather-user-check"></i> Participante
                                                                </span>
                                                            ) : detallesParticipacion.estado === "validando" ? (
                                                                <span className="rbt-btn btn-xs bg-color-warning-opacity radius-round" style={{background: '#fff',  color: '#fff', fontSize:'16px'}}>
                                                                  <i className="feather-info"></i> Validando
                                                                </span>
                                                              ) : detallesParticipacion.estado === "finalizado" ? (
                                                                <span className="rbt-btn btn-xs bg-color-success-opacity radius-round color-success" style={{background: '#fff',  color: '#fff', fontSize:'16px'}}>
                                                                  <i className="feather-award"></i> Finalizado
                                                                </span>
                                                              ) : (
                                                                detallesParticipacion.estado
                                                              )}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                </table>
                                            ):null}
                                        </div>
                                    </div>
                                ):null}
                                </div>
                        </div>
                    </div>
                </div>
                {/*
                <div className="thumb-wrapper bg-color-white rbt-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="swiper rbt-gif-banner-area rbt-arrow-between swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                                    <div className="swiper-wrapper" >
                                        <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-swiper-slide-index="2" style={{width: '1305px' }}role="group" aria-label="3 / 3">
                                            <div className="thumbnail">
                                                    <img className="rbt-radius w-100" src="../assets/images/banner/gallery-banner-03.jpg" alt="Banner Images" />
                                            </div>
                                        </div>
                                    </div>
    
                                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                            </div>
                        </div>
                    </div>
    
                    <div className="container" style={{paddingTop:'60px'}}>
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="content">
                                    <div className="inner text-center">
                                        <div className="slider-btn rbt-button-group justify-content-center">
                                            <a className="rbt-btn btn-gradient hover-icon-reverse" href="#">
                                                <span className="icon-reverse-wrapper">
                                                    <span className="btn-text">Log in to Start</span>
                                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                </span>
                                            </a>
                                            <a className="rbt-btn hover-icon-reverse btn-white" href="#">
                                                <span className="icon-reverse-wrapper">
                                                    <span className="btn-text">Buy The Course</span>
                                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                <span className="btn-icon"><i className="feather-arrow-right"></i></span>
                                                </span>
                                            </a>
                                        </div>
                                        <div className="row mt--20">
                                            <a href="contact.html">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                */}

            </div>
        )
    }
    
}

export default ListaDeLogros;